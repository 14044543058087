<template>
  <div class="wrapper" :style="wrapperStyle">
    <div
      class="container"
      :style="containerStyle"
      id="container"
      @mousedown="contain.handleInitActive()"
      ref="container"
    >
      <div class="refer-select" :style="selectStyle"></div>
      <div
        class="grade"
        v-if="gradeFlag || contain.config.gradeShow"
        :style="gradeLenStyle"
      ></div>
      <subgroup ref="subgroup" :nav="contain.list"></subgroup>
    </div>
    <wechat ref="wechat"></wechat>
  </div>
</template>

<script>
import wechat from '@/page/wechat/'
import subgroup from './subgroup'
import { loadScript } from '@/utils/utils'
import { config as defaultConfig } from '@/option/config'
import { getObj } from '@/api/visual'
import { website } from '@/config.js'
import { getToken } from '@/utils/auth';
import Vue from 'vue'
Vue.prototype.$website = website;
window.$loadScript = loadScript;
export default {
  name: 'contents',
  inject: ["contain"],
  props: {
    target: String,
    option: Object,
    id: [String, Number]
  },
  provide() {
    return {
      contain: this.contain,
      container: this
    };
  },
  components: {
    subgroup,
    wechat
  },
  data() {
    return {
      select: {
        startX: '',
        startY: '',
        endX: '',
        endY: '',
        show: false
      },
      selectCount: {
        x1: null,
        x2: null,
        y1: null,
        y2: null
      },
      wrapperStyle: {},
      gradeFlag: false,
    }
  },
  computed: {
    selectStyle() {
      let x = this.select.endX - this.select.startX;
      let y = this.select.endY - this.select.startY;
      return {
        top: this.setPx(y > 0 ? this.select.startY : this.select.endY),
        left: this.setPx(x > 0 ? this.select.startX : this.select.endX),
        width: this.setPx(Math.abs(x)),
        height: this.setPx(Math.abs(y)),
        display: this.select.show ? 'block' : 'none'
      }
    },
    stepScale() {
      let result = Number(100 / (this.contain.scale * 100))
      return result
    },
    //计算中央可视化大屏比例
    containerStyle() {
      const widthVal = (this.contain.width / this.contain.config.width)
      const heightVal = (this.contain.height / this.contain.config.height)
      let scaleX = widthVal, scaleY = widthVal;
      if (!this.isBuild) {
        let screen = this.contain.config.screen;
        if (screen == 'x') {
          this.contain.viewStyle = {
            'overflow-y': 'auto'
          }
        } else if (screen == 'y') {
          scaleX = heightVal;
          scaleY = heightVal;
          this.contain.viewStyle = {
            'overflow-x': 'auto'
          }
        } else if (screen == 'xy') {
          scaleX = widthVal;
          scaleY = heightVal;
        }
      } else {
        scaleX = 1;
        scaleY = 1;
      }
      this.wrapperStyle = {
        width: this.setPx(this.contain.config.width * scaleX),
        height: this.setPx(this.contain.config.height * scaleY),
      }
      return Object.assign({
        transform: `scale(${scaleX}, ${scaleY})`,
        width: this.setPx(this.contain.config.width),
        height: this.setPx(this.contain.config.height),
        backgroundColor: this.contain.config.backgroundColor
      }, (() => {
        if (this.contain.config.backgroundImage) {
          return {
            background: `url(${this.contain.config.backgroundImage}) 0% 0% / 100% 100% rgb(3, 12, 59)`,
          }
        }
        return
      })())
    },
    gradeLenStyle() {
      return {
        backgroundSize: `${this.setPx(this.contain.config.gradeLen)} ${this.setPx(this.contain.config.gradeLen)},${this.setPx(this.contain.config.gradeLen)} ${this.setPx(this.contain.config.gradeLen)}`
      }
    },
    isBuild() {
      return this.$route ? this.$route.name === 'build' : false;
    }
  },
  mounted() {
    // this.$refs.wechat.show()
    this.initData();
    this.initFun();
    window.onresize = () => {
      this.setScale()
    }
  },
  methods: {
    dragMousedown(e) {
      this.contain.handleInitActive()
      this.select.startX = e.offsetX
      this.select.startY = e.offsetY
      this.select.endX = this.select.startX
      this.select.endY = this.select.startY
      this.select.show = true
    },
    dragMousemove(e) {
      if (!this.select.show) return
      this.select.endX = e.offsetX
      this.select.endY = e.offsetY
    },
    dragMouseup(e) {
      if (this.select.show) {
        let selectIndex = []
        this.contain.list.forEach(ele => {
          let x = ele.left >= this.select.startX && ele.left <= this.select.endX
          let y = ele.top >= this.select.endX && ele.top <= this.select.endY
          if (x || y) selectIndex.push(ele.index)
        })
        this.contain.selectNav(selectIndex)
        this.select.show = false
      }

    },
    getTargetDom() {
      let target = this.target || (this.isBuild ? '#section' : 'body')
      return document.querySelector(target)
    },
    initFun() {
      ['handleRefresh', 'getListRef', 'getItemRef'].forEach(ele => {
        this[ele] = this.$refs.subgroup[ele]
      });
    },
    //初始化数据
    initData() {
      const id = this.id || (this.$route && this.$route.params.id)
      let config;
      const callback = () => {
        //赋值属性
        let mark = this.contain.config.mark;
        if (mark.show && !this.isBuild) {
          this.watermark(Object.assign(mark, {
            fontSize: mark.fontSize + 'px'
          }));
        }
        this.calcData();
        this.setScale();
      }
      if (id) {
        const loading = this.$loading({
          lock: true,
          text: '正在加载中，请稍后',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        getObj(id).then(res => {
          const data = res.data.data;
          this.contain.obj = data;
          config = data.config;
          const contain = {
            config: JSON.parse(config.detail) || {},
            component: JSON.parse(config.component) || []
          }
          function convert(elm) {
            let reg = new RegExp('=&gt;', 'g')//g代表全部
            if (elm.dataFormatter) {
              elm.dataFormatter = elm.dataFormatter.replace(reg, "=>");
            }
            if (elm.dataQuery) {
              elm.dataQuery = elm.dataQuery.replace(reg, "=>");
            }
            if (elm.time < 2 * 60 * 1000) {
              elm.time = 120 * 1000;
            }
          }
          contain.component.forEach(elm => {
            convert(elm);
            if (elm.children && elm.children.length > 0) {
              elm.children.forEach(el => {
                convert(el);
              })
            }
          })

          let siksoftInfo = JSON.parse(localStorage.getItem('saber-userInfo')) || {};
          // if (siksoftInfo) {
          // let token = siksoftInfo.token;
          let token = "bearer " + getToken();
          let header = "function(){\n    return {\n        'Blade-Auth':'" + token + "'\n    }\n}";
          let query = "function(){\n    return {\n        'tenantId':'" + siksoftInfo.tenant_id + "'\n    }\n}";

          this.$set(contain.config, 'header', header);
          this.$set(contain.config, 'query', query);
          // this.$set(contain.config, 'title', data.visual.title || "");
          // console.log(contain, data, "contain");
          // }
          this.contain.config = Object.assign({}, defaultConfig, contain.config);
          this.contain.nav = contain.component
          this.contain.visual = data.visual;
          document.title = this.$website.title + '-' + data.visual.title
          //添加水印。只有查看页面生效
          if (!this.isBuild) {
            const password = this.contain.visual.password
            const status = this.contain.visual.status
            if (status == 0) {
              this.$alert('大屏还没有发布，晚一点再来吧！', '提示', {
                showClose: false,
                center: true,
                showConfirmButton: false,

              });
            } else if (!this.validatenull(password)) {
              this.$prompt('请输入密码', '提示', {
                confirmButtonText: '确定',
                showCancelButton: false,
                showClose: false,
                closeOnClickModal: false,
                inputPattern: new RegExp(password),
                inputErrorMessage: '密码不正确，请重新输入'
              }).then(() => {
                callback();
              })
            } else {
              callback();
            }
          } else {
            callback();
          }
          loading.close();
        }).catch((err) => {
          console.log(err)
          callback();
          loading.close();
        })
      } else if (this.option) {
        config = this.option;
        this.contain.config = config.detail || {};
        this.contain.nav = config.component || [];
        callback();
      } else {
        this.setScale();
      }
    },
    //计算比例
    setScale(width) {
      const thick = this.contain.thick || 0
      this.contain.canvasWidth = this.contain.config.width;
      this.contain.canvasHeight = this.contain.config.height;
      this.contain.width = this.getTargetDom().offsetWidth - thick
      this.contain.height = this.getTargetDom().offsetHeight - thick
      this.contain.scale = this.contain.width / this.contain.config.width - 0.02
      this.$nextTick(() => {
        this.contain.initSize && this.contain.initSize()
      })
    },
    calcData() {
      if (!this.contain.config.mark) this.contain.config.mark = {}
      if (!this.contain.config.query) this.contain.config.query = {}
    },
    handlePostionSelect(postion) {
      this.contain.activeIndex = null
      this.handleCalcPostionSelect();
      const x1 = this.selectCount.x1;
      const x2 = this.selectCount.x2;
      const y1 = this.selectCount.y1;
      const y2 = this.selectCount.y2;
      if (postion === 'left') {
        this.handleMoveSelectList(x1, undefined, true, postion);
      } else if (postion === 'center') {
        this.handleMoveSelectList(x1 + (x2 - x1) / 2, undefined, true, postion);
      } else if (postion === 'right') {
        this.handleMoveSelectList(x2, undefined, true, postion);
      } else if (postion === 'top') {
        this.handleMoveSelectList(undefined, y1, true, postion);
      } else if (postion === 'middle') {
        this.handleMoveSelectList(undefined, y1 + (y2 - y1) / 2, true, postion);
      } else if (postion === 'bottom') {
        this.handleMoveSelectList(undefined, y2, true, postion);
      }
    },
    handleMoveSelectList(left, top, type, postion) {
      this.contain.active.forEach(ele => {
        let item = this.contain.findList(ele)
        const component = item.component;
        //水平情况
        if (left) {
          let baseLeft = Number(type ? left : (item.left + left).toFixed(2));
          if (postion === 'right') {
            baseLeft = baseLeft - component.width
          }
          else if (postion === 'center') {
            const obj_center = item.left + component.width / 2;
            baseLeft = item.left + (left - obj_center)
          }
          item.left = baseLeft
        }
        //垂直情况
        if (top) {
          let baseTop = Number(type ? top : (item.top + top).toFixed(2));
          if (postion === 'bottom') {
            baseTop = baseTop - component.height
          }
          else if (postion === 'middle') {
            const obj_middle = item.top + component.height / 2;
            baseTop = item.top + (top - obj_middle)
          }
          item.top = baseTop
        }
      })
    },
    //计算多选状态下的最大边界值
    handleCalcPostionSelect() {
      this.selectCount = {
        x1: null,
        x2: null,
        y1: null,
        y2: null
      }
      this.contain.active.forEach(ele => {
        ele = this.contain.findList(ele)
        const left = ele.left;
        const top = ele.top;
        const width = ele.component.width;
        const height = ele.component.height;
        if (!this.selectCount.x1) {
          this.selectCount = {
            x1: left,
            x2: left + width,
            y1: top,
            y2: top + height
          }
        }
        if (this.selectCount.x1 > left) this.selectCount.x1 = left;
        if (this.selectCount.x2 < left + width) this.selectCount.x2 = left + width;
        if (this.selectCount.y1 > top) this.selectCount.y1 = top;
        if (this.selectCount.y2 < top + height) this.selectCount.y2 = top + height;
      })
    },
  }
}
</script>

<style lang="scss">
@import '@/styles/echart.scss';
@import '@/styles/style.scss';
</style>